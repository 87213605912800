import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { StyledLink } from '../../App.js';
import { logOut } from '../routes/auth-utils';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--lightgray);
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  nav:first-child {
      /* margin-top: 100px; */
  }

  @media (max-width: 576px) {
    width: 70%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 1.7rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0D0C1D;
    text-decoration: none;

    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
`


const StyledBurger = styled.button`
  position: absolute;
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? '#0D0C1D' : 'black'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const Logout = styled.a`
  cursor: pointer;
`

export const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

export const Menu = ({ open, setOpen }) => {
  const history = useHistory();

  return (
    <StyledMenu open={open} onClick={() => setOpen(!open)} >
      <StyledLink to="/start">
        <span>Start</span>
      </StyledLink>
      <StyledLink to="/services">
        <span>Usługi</span>
      </StyledLink>
      <StyledLink to="/payments">
        <span>Płatności</span>
      </StyledLink>
      <StyledLink to="/messages">
        <span>Wiadomości</span>
      </StyledLink>
      <StyledLink to="account">
        <span>Moje konto</span>
      </StyledLink>
      <Logout onClick={() => logOut(history)}>
        <span>Wyloguj</span>
      </Logout>
    </StyledMenu>
  )
}
