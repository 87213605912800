import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useLocation } from "react-router-dom";
import * as Yup from 'yup';

import { ContentBox } from '../components/boxes/content-box.component';
import { GreyBox } from '../components/boxes/grey-box.component';
import { InputAndLabel } from '../components/content/input-and-label.component';
import { KorbankButton } from '../components/content/buttons.component';
import {
  Container, SiteTitle, BoxContainer,
  Feedback, InputWrapper, ButtonWrapper,
  FeedbackWrapper
} from './login.page';
import { ClientChangePasswordTokenApi } from '../api/api-client';

export const ResetPasswordPage = () => {
  const [feedback, setFeedback] = useState('');
  let location = useLocation();
  const array = location.pathname.split('/')
  const username = array[2];
  const token = array[3];

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      repeatedNewPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(12, 'Hasło musi mieć co najmniej 12 znaków')
        .required('Pole jest wymagane'),
      repeatedNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Nowe hasła nie są identyczne')
        .required('Pole jest wymagane'),
    }),

    onSubmit: async (values) => {
      const result = await new ClientChangePasswordTokenApi({
        username: username,
        token: token,
        new_password: values.newPassword
      }).post();

      if (result.status === 200) {
        setFeedback('Hasło zostało ustawione');
      } else {
        let content = result.data.detail;
        if (!content){
          content = result.data[0];
        }
        setFeedback(content);
      }
    }
  });
  return (
    <Container>
      <SiteTitle>
        Reset hasła użytkownika {username}
      </SiteTitle>
      <ContentBox title="Reset" size='35%' margin="0px">

        <BoxContainer onSubmit={formik.handleSubmit}>
          <GreyBox>
            <InputWrapper>
              <InputAndLabel
                label="Nowe hasło"
                type="password"
                id="newPassword"
                {...formik.getFieldProps('newPassword')} />

              {formik.touched.newPassword && formik.errors.newPassword ? (
                <Feedback>{formik.errors.newPassword}</Feedback>
              ) : null}
            </InputWrapper>

            <InputWrapper>
              <InputAndLabel
                label="Powtórz nowe hasło"
                type="password" id="repeatedNewPassword"
                {...formik.getFieldProps('repeatedNewPassword')} />

              {formik.touched.repeatedNewPassword && formik.errors.repeatedNewPassword ? (
                <Feedback>{formik.errors.repeatedNewPassword}</Feedback>
              ) : null}
              <FeedbackWrapper>
                {feedback}
              </FeedbackWrapper>
            </InputWrapper>

            <ButtonWrapper>
              <KorbankButton content="Ustaw hasło" />
            </ButtonWrapper>

          </GreyBox>
        </BoxContainer>

      </ContentBox>

    </Container>
  )
}