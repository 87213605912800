import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ContentBox } from '../components/boxes/content-box.component';
import { DropableContent } from '../components/wrappers/dropable-content.component';

import { ClientDataApi } from '../api/api-client';

const Container = styled.div`
  margin-bottom: 100px;

  @media(max-width: 1000px){
    margin-top: 125px;
  }
`

export const ServicesPage = () => {
  const [data, setData] = useState({});
  const [refetch, setRefetch] = useState('false');

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientDataApi().get();
      setData(result.data);
    }
    fetchData();
  }, [refetch])


  let lines = [];
  if (Object.keys(data).length !== 0) {
    const content = dataToMaps(data);
    lines = content.map((item, index) => {
      return <DropableContent
        content={item}
        key={index}
        refetch={refetch}
        setRefetch={setRefetch} />
    })
  }

  return (
    <Container>
      {Object.keys(data).length !== 0 &&
        <ContentBox title="Moje Usługi" size="100%">
          {lines}
        </ContentBox>
      }
    </Container>
  )
}

// https://stackoverflow.com/a/24039448/12422260
const addZeroes = (num) => {
  num = num.toString();
  const dec = num.split('.')[1]
  const len = dec && dec.length > 2 ? dec.length : 2
  return Number(num).toFixed(len)
}

const dataToMaps = (data) => {
  const mappedData = data.active_computers.map((element) => {
    // map for preserving order;
    let content = new Map();

    // checking which fields exist, adding polish title
    if (element.id) {
      content.set('id', {
        content: element.id
      })
    }

    if (element.user_description) {
      content.set('user_description', {
        name: 'Opis użytkownika',
        content: element.user_description,
        editable: true,
      })
    } else {
      content.set('user_description', {
        name: 'Opis użytkownika',
        content: '',
        editable: true
      });
    }

    if (element.id_client_data.address_1st_line || element.id_client_data.address_2nd_line) {
      content.set('address', {
        name: 'Adres instalacji',
        content: `${element.id_client_data.address_1st_line} ${element.id_client_data.address_2nd_line}`
      });
    }

    if (element.ipv4) {
      if (element.netmask && element.netmask !== 32) {
        content.set('ipv4', {
          content: 'Adres IP',
          value: `${element.ipv4}/${element.netmask}`
        })
      } else {
        content.set('ipv4', {
          name: 'Adres IP',
          content: `${element.ipv4}`
        })
      }
    }

    if (element.ipv6) {
      content.set('ipv6', {
        name: 'Adres IPv6',
        content: element.ipv6
      })
    }

    if (element.kpanel_mac_allow) {
      content.set('mac', {
        name: 'Adres MAC',
        content: element.mac,
        editable: true,
      })
    }

    if (element.tariff_name === "Telefon" && element.voip_no) {
      content.set('voip', {
        name: 'Numer telefonu VOIP',
        content: element.voip_no
      })
    }

    if (element.tariff_name) {
      content.set('tariff_name', {
        name: 'Taryfa',
        content: element.tariff_name
      })
    }

    if (element.devicename === "Komputer") {
      if (element.cfg_params.downlink) {
        content.set('cfg_params.downlink', {
          name: 'Prędkość pobierania',
          content: `do ${element.cfg_params.downlink} kbps`
        })
      }

      if (element.cfg_params.uplink) {
        content.set('cfg_params.uplink', {
          name: 'Prędkość wysyłania',
          content: `do ${element.cfg_params.uplink} kbps`
        })
      }
    }

    // strange, but it's how it was in old panel
    if (element.devicename !== 'Telefon') {
      content.set('brutto', {
        name: 'Abonament',
        content: `${addZeroes(element.brutto)} PLN/m-c`
      })
    }

    if (element.active_extras.length > 0) {
      content.set('active_extras', {
        name: 'Usługi dodatkowe',
        extras: []
      })

      element.active_extras.forEach((extra) => {
        let obj = {
          name: extra.extra_name,
          id: extra.id,
          content: [{
            name: 'Abonament',
            content: `${addZeroes(extra.brutto)} PLN/mc`
          }]
        };
        if (extra.extra_comp && element.kpanel_mac_allow) {
          if (extra.ipv4) {
            obj.content.push({
              name: 'Adres IP',
              content: extra.ipv4
            })
          }
          obj.content.push({
            name: 'Adres MAC',
            content: extra.mac,
            editable: true,
          })
        }

        let map_content = content.get('active_extras');
        map_content.extras.push(obj);
        content.set('active_extras', map_content);
      });
    }

    if (element.price_with_extras) {
      content.set('price_with_extras', {
        name: 'Razem abonament',
        content: `${addZeroes(element.price_with_extras)} PLN/m-c`
      })
    }

    return content
  });

  return mappedData;
}