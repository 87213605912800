import React from 'react';
import styled from 'styled-components';
import { Dot } from '@styled-icons/bootstrap';

const Container = styled.div`
  font-size: 15px;

  @media(max-width: 1000px){
    font-size: var(--mini-size);
  }
`

const Key = styled.div`
`

const Value = styled.div`
`

const Line = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 90%;
`

const SmallText = styled.p`
  font-size: var(--mini-size);
`

const ColorDot = styled(Dot)`
  color: ${(props) => (props.value ? 'green' : 'red')};
  margin-top: -5px;
  width: 30px;

`

export const KeyValueContent = (props) => {
  let line = '';

  if (props.content[3]) {
    line = <Line>
      <ColorDot value={props.content[0]} />
      <Value>{props.content[1]}</Value>
      <Value>{props.content[2]}</Value>
      <Value>{props.content[3]}</Value>
    </Line>
  }
  // rendering last payment
  else if (props.content[2]) {
    line = <Line>
      <Key>
        {props.content[0]}:
        <SmallText>(dnia {props.content[2]})</SmallText>
      </Key>
      <Value>
        {props.content[1]}
      </Value>
    </Line>
  }
  // rendering finances, personal data or messages
  else {
    line = <Line>
      <Key>{props.content[0]}:</Key>
      <Value>{props.content[1]}</Value>
    </Line>
  }

  return (
    <Container>
      {line}
    </Container>
  )

}