import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ContentBox } from '../components/boxes/content-box.component';
import { TableRowWrapper, TableRowWithDetailsWrapper } from '../components/content/table-row-wrapper.component';
import { FilterTableRow } from '../components/wrappers/filter-table-row.component';
import { Details } from './start.page';
import { ClientFinancesApi, ClientDebtApi } from '../api/api-client';


const Container = styled.div`
  width: 100%;
  margin-bottom: 100px;

  @media(max-width: 1000px){
    margin-top: 125px;
    font-size: var(--mobile-mini-size);
  }
`

const DetailsWrapper = styled.div`
  padding: 30px;
  text-align: center;
  cursor: pointer;
`

const DummyDiv = styled.div`
`

export const FinancesPage = () => {
  const [year, setYear] = useState('');
  const [company, setCompany] = useState('');
  const [data, setData] = useState({});
  const [nOfDocuments, setNOfDocuments] = useState(4);
  const [debt, setDebt] = useState({});
  const bottomRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientFinancesApi().get();
      setData(result.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientDebtApi().get();
      setDebt(result.data);
    }
    fetchData();
  }, []);


  useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [nOfDocuments]);

  let invoices = [];
  if (Object.keys(data).length !== 0) {

    // sort
    const sorted_dates = data.financial_documents.sort((a, b) => {
      return Date.parse(b.date) - Date.parse(a.date);
    })

    // form data into sorted array (0 - base, 1 - folded)
    const final_invoices_list = sorted_dates.map((element) => {
      let toPay = ''
      if (element.to_pay === undefined) {
        toPay = 'Nie dotyczy'
      } else if (Number.parseFloat(element.to_pay) === 0) {
        toPay = 'Opłacona'
      } else {
        toPay = `Do zapłaty: ${element.to_pay}zł`
      }
      return [[element.provider_name, element.date.split('T')[0], element.maturity, `${element.brutto}zł`, toPay],
      ['Numer faktury', element.nr],
      element.id,
      element.codename
      ]
    })

    // filter and return component
    invoices = final_invoices_list.filter((item) => {
      if (year) {
        return item[0][1].split('-')[0].includes(year)
      } else return item
    })
      .filter((item) => {
        // item[0][0] prevents from error from empty provider field
        if (company && item[0][0]) {
          return item[0][0].toLocaleLowerCase().includes(company.toLocaleLowerCase());
        } else return item;
      })
      .map((item) => (
        <TableRowWithDetailsWrapper key={item[2]} content={item[0]} foldable={item[1]} id={item[2]} version={item[3]} />
      ));

    invoices = invoices.slice(0, nOfDocuments);
  }

  return (
    <Container>
      {Object.keys(data).length !== 0 && Object.keys(debt).length !== 0 &&
        <>
          <ContentBox title="Rachunki i płatności" size="100%" margin="0">
            <FilterTableRow balance={Object.keys(data).length !== 0 ? debt.balance : ''} setYear={setYear} setCompany={setCompany} />
            <TableRowWrapper content={['Firma', 'Data wystawienia', 'Termin płatności', 'Kwota', 'Status']} background={'var(--lightgray)'} />
            {invoices}
            <DetailsWrapper>
              <Details onClick={() => {
                // show more
                setNOfDocuments(nOfDocuments + 6);
              }}>
                Zobacz więcej
          </Details>
            </DetailsWrapper>
          </ContentBox>
        </>
      }
      <DummyDiv ref={bottomRef}></DummyDiv>
    </Container>
  )
}