import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
`

const Label = styled.label`
  display: block
`

const Input = styled.input`
  display: block;
  width: 300px;
  padding: 10px;
  margin-top: 15px;
  border-radius: 5px;
  border: none;

  @media(max-width: 1000px){
    width: 200px;
  }
`

export const InputAndLabel = (props) => {

  return (
    <Container>
      <Label>{props.label}</Label>
      <Input type={props.type} placeholder={props.placeholder} {...props}></Input>
    </Container>
  )
}