import React, { useState } from 'react';
import styled from 'styled-components';

import { TextWrapper } from '../boxes/content-box.component';
import { TableRowContent } from './table-row-content.component';
import { ClientInvoicePdfApi, ClientMessagePdfApi } from '../../api/api-client';

const Container = styled.div`
  width: 100%;
  background-color: ${(props) => (props.background ? props.background : 'white')};
  padding: 30px 0 20px 0;
  margin-top: ${(props) => (props.top ? '-10px' : '0')};
  border-bottom: ${props => props.border ? props.border : '1px solid var(--lightgray)'};

  @media(max-width: 1000px){
    text-align: center;
  }
`

const TextContainer = styled.div`
  margin-left: 10px;
`

const Details = styled.div`
  /* https://stackoverflow.com/a/9492675/12422260 */
  white-space: pre-wrap;
  margin-top:30px;
`

const Row = styled.div`
  display: flex;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 7em;

`

const Generator = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
`

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const Link = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  margin-top: 0;
`

const Text = styled.p`
  display: block;
  font-weight: 'lighter';
  margin-top: 0;
`

const Feedback = styled.p`
  color: red;
  margin-left: 10px;
`

// row without folded details
export const TableRowWrapper = (props) => {

  return (
    <Container background={props.background} border="none" top={props.top}>
      <TextContainer>
        <TableRowContent content={props.content} />
      </TextContainer>
    </Container>
  )
}


const FoldableDetails = (props) => {
  const [data, setData] = useState({});

  return (
    <Container background={props.background} border="none" top={props.top}>
      <TextWrapper>
        <Row>

          <Column>
            <Title>{props.content[0]}</Title>
            <Text>{props.content[1]}</Text>
          </Column>
          <Column>
            <Generator onClick={async () => {
              const result = await new ClientInvoicePdfApi({ id_invoice: props.id }).get()
              setData(result.data);
            }}>Wygeneruj fakturę</Generator>
            {Object.keys(data).length !== 0 &&
              <Link href={`data:application/pdf;base64,${data.b64_content}`} download={data.name}>Pobierz</Link>
            }
          </Column>

        </Row>
      </TextWrapper>
    </Container>
  )
}


const FoldableMessageFile = (props) => {
  const [data, setData] = useState({});

  return (
    <Container background={props.background} border="none" top={props.top}>
      <TextWrapper>
        <Row>
          <Column>
            <Generator onClick={async () => {
              const result = await new ClientMessagePdfApi({ id_message: props.id }).get()
              setData(result.data);
            }}>Wygeneruj plik</Generator>
            {Object.keys(data).length !== 0 &&
              <Link href={`data:${data.file_content_type};base64,${data.file_b64_content}`} download={data.file_name}>Pobierz</Link>
            }
          </Column>

        </Row>
      </TextWrapper>
    </Container>
  )
}

// row with folded details
export const TableRowWithDetailsWrapper = (props) => {
  const [folded, setFolded] = useState(true)
  const [feedback, setFeedback] = useState('');

  let foldableContent = 'brak dodatkowych danych';

  if (props.version === 'FV') {
    foldableContent = <FoldableDetails content={props.foldable} id={props.id} />
  } else if (props.version === 'fileMessage') {
    foldableContent = <FoldableMessageFile id={props.id} />
  }
  else if (props.version === 'message') {
    foldableContent = props.foldableContent;
  }

  const details = !folded ? <Details>
    {foldableContent}
  </Details> : '';

  return (
    <Container>
      <TextContainer>
        <TableRowContent content={props.content} onClick={async (e) => {
          setFolded(!folded);

          if (props.version === 'message' || props.version === 'fileMessage') {
            try {
              const today = new Date();
              const currentTime = `${today.year}-${today.month}-${today.day}T${today.hour}:${today.minute}:${today.second}`;
              await new ClientMessagePdfApi({ id_message: props.id, read_time: currentTime }).get();
              props.setRefetch(!props.refetch);
            } catch (e) {
              if (folded){
                setFeedback('Błąd pobierania danych. Skontaktuj się z Biurem Obsługi Klienta.');
              } else {
                setFeedback('');
              }
            }
          }
        }} />
        {details}
        <Feedback>
          {feedback}
        </Feedback>
      </TextContainer>
    </Container>
  )
}