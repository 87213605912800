import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import * as Yup from 'yup';
import "yup-phone";

import { ContentBox } from '../components/boxes/content-box.component';
import { EditableContent } from '../components/content/editable-content.component';
import { Container } from './start.page';
import { ClientDataApi, ClientContactDictionaryApi, ClientConsentApi } from '../api/api-client';
import { AddContact } from '../components/content/add-contact.component';
import { KeyValueContent } from '../components/content/key-value-content.component';
import { ChangePassword } from '../components/content/change-password.component';
import { ConsentContent } from '../components/content/consent-content.component';

const FIELD_EMPTY = 'Pole nie może być puste. ';
const FIELD_EMAIL = 'Proszę podać poprawny adres email. ';
const FIELD_PHONE = 'Proszę podać poprawny numer telefonu. ';

const ButtonWrapper = styled.div`
  display: flex;
`

const AddButton = styled.button`
  margin: 10px 10px 20px 0;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`

const FeedbackWrapper = styled.div`
  margin-bottom: 10px;
`

const SurveyText = styled.p`
  font-size: var(--content-size);
  width: 90%;
  text-align: justify;
  line-height: 1.5;

  @media(max-width: 1000px){
    font-size: var(--mobile-mini-size);
  }
`

export const AccountPage = () => {

  // client data
  const [data, setData] = useState({});

  // fetched client contact data
  const [clientContact, setClientContact] = useState({});

  // consents data
  const [consents, setConsents] = useState({})

  // fetch again when adding or editing succeds
  const [refetch, setRefetch] = useState(false)

  // manage displaying forms
  const [show, setShow] = useState('')

  const [feedback, setFeedback] = useState('');

  // https://www.npmjs.com/package/yup-phone
  const phoneSchema = Yup.string().phone('PL', true, FIELD_PHONE).required(FIELD_EMPTY);
  const mailSchema = Yup.string().email(FIELD_EMAIL).required(FIELD_EMPTY);
  const stringSchema = Yup.string().required(FIELD_EMPTY);
  let phones = [], emails = [];

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientDataApi().get();
      setData(result.data);
      console.log(result.data);
    }
    fetchData();
  }, [refetch])

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientContactDictionaryApi().get();
      setClientContact(result.data);
    }
    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientConsentApi().get();
      setConsents(result.data);
    }
    fetchData();
  }, [])

  if (Object.keys(data).length !== 0) {
    phones = data.clientcontact_set.map((element) => {
      if (!element.is_mail) {
        return <EditableContent
          key={element.id}
          id={element.id}
          content={[element.name, element.contact]}
          validation={phoneSchema}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      } else return null;
    })

    emails = data.clientcontact_set.map((element) => {
      if (element.is_mail) {
        return <EditableContent
          key={element.id}
          id={element.id}
          content={[element.name, element.contact]}
          validation={mailSchema}
          refetch={refetch}
          setRefetch={setRefetch} />
      } else return null;
    })
  }

  return (
    <Container>
      {/* https://pl.reactjs.org/docs/conditional-rendering.html#inline-if-with-logical--operator */}
      { Object.keys(data).length !== 0 && Object.keys(consents).length !== 0 && <>
        <ContentBox title="Moje dane" size="47%">
          <KeyValueContent content={['Numer klienta', data.code]} />
          <KeyValueContent content={['Imię i nazwisko/nazwa firmy', `${data.name} ${data.surname}`]} />
        </ContentBox>

        <ContentBox title="Adresy" size="47%">
          <KeyValueContent content={['Adres zameldowania/siedziby', `${data.main_data.address_1st_line} ${data.main_data.address_2nd_line}`]} />
          <KeyValueContent content={['Adres na fakturze', `${data.data_for_inv.address_1st_line} ${data.data_for_inv.address_2nd_line}`]} />
        </ContentBox>

        <ContentBox title="Dane kontaktowe" size="47%">
          {phones}
          {emails}

          <ButtonWrapper>
            <AddButton onClick={() => {
              if (show !== 'email') {
                setShow('email');
              } else {
                setShow('');
              }
            }}>Dodaj email</AddButton>

            <AddButton onClick={() => {
              if (show !== 'phone') {
                setShow('phone');
              } else {
                setShow('');
              }
            }}>Dodaj telefon</AddButton>
          </ButtonWrapper>

          {show === 'phone' &&
            <AddContact
              type="phone"
              setRefetch={setRefetch}
              refetch={refetch}
              clientContact={clientContact}
              contactValidation={phoneSchema}
              stringValidation={stringSchema} />
          }

          {show === 'email' &&
            <AddContact
              type="email"
              setRefetch={setRefetch}
              refetch={refetch}
              clientContact={clientContact}
              contactValidation={mailSchema}
              stringValidation={stringSchema} />
          }
        </ContentBox>

        <ContentBox title="Zgody" size="47%">
          <ConsentContent
            label='Zgoda marketingowa'
            checked={consents.consent_marketing}
            apiName='consent_marketing'
          />
          <ConsentContent
            label='Zgoda na e-fakturę'
            checked={consents.consent_einvoice}
            apiName='consent_einvoice'
          />
        </ContentBox>

        <ContentBox title="Dane logowania" size="47%">
          <KeyValueContent content={['Login', sessionStorage.getItem('login')]} />
          <AddButton onClick={() => {
            if (show !== 'password') {
              setShow('password');
            } else {
              setShow('');
            }
          }}>Zmień hasło</AddButton>
          {show === 'password' &&
            <ChangePassword
              validation={stringSchema}
              setShow={setShow}
              setFeedback={setFeedback}
            />
          }
          <FeedbackWrapper>
            {feedback}
          </FeedbackWrapper>
        </ContentBox>

        <ContentBox title="Dobrowolna ankieta" size="47%">
          <SurveyText>
            Drodzy Klienci,<br />
            Dziękujemy, że jesteście z nami! Dbając o rozwój, doskonalenie naszych usług,
            zwracamy się z prośbą o pozostawienie opinii na temat usług świadczonych przez naszą firmę.
            Za udzielenie rzetelnych odpowiedzi, z góry dziękujemy!
          </SurveyText>
          <SurveyText>
            Czy są Państwo zadowoleni ze współpracy z naszą firmą?
          </SurveyText>
          <AddButton onClick={() => {
            window.open("https://www.google.com/search?q=korbank+opinie&oq=korbank+opinie&aqs=chrome.0.69i59j0i22i30l3j69i64j69i65j69i61.2360j0j4&sourceid=chrome&ie=UTF-8#lrd=0x470fc209399656e1:0x1c65aebda4f80603,1",
              "_blank")
          }}>
            Tak
          </AddButton>
          <AddButton onClick={() => {
            window.open("https://korbank.pl/pk-21/", "_blank")
          }}>
            Nie
          </AddButton>
        </ContentBox>
      </>}

    </Container>
  )
}