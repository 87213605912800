import { MIDDLEWARE_IP } from '../settings';
import axios from 'axios';


class MiddlewareApiClient {

  constructor() {
    this.base_url = `${MIDDLEWARE_IP}`
    this.url = ""
    this.data = {}
    this.headers = { 'Content-type': 'application/json' }
  }

  get = () => {
    return axios.get(this.url, { headers: this.headers })
  }

  post = () => {
    return axios.post(this.url, this.data, { headers: this.headers })
  }

  patch = () => {
    return axios.patch(this.url, this.data, { headers: this.headers })
  }

  put = () => {
    return axios.put(this.url, this.data, { headers: this.headers })
  }

  delete = () => {
    return axios.delete(this.url, {
      headers: { ...this.headers },
      data: { ...this.data }
    })
  }
}

class AbstractMiddlewareClientApi extends MiddlewareApiClient {
  constructor(resource_name, data = {}) {
    super();
    this.resource_name = resource_name
    this.url = `${this.base_url}/${this.resource_name}/`
    this.data = data !== {} ? data : {};
  }
}

// public
class ClientLoginApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest-login', data);
  }
}

class ClientCheckTokenApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest-verify', data);
  }
}

class ClientChangePasswordSendApi extends AbstractMiddlewareClientApi {
  constructor(data){
    super('reset-password', data);
  }
}

class ClientChangePasswordTokenApi extends AbstractMiddlewareClientApi {
  constructor(data){
    super('reset-password-token', data);
  }
}

// private
class ClientDataApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest/client_details', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientDebtApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest/client_debt', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientFinancesApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest/client_finances', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientContactDictionaryApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest/contact_dictionary', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientContactApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest/client_contact', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientChangeDescOrMacApi extends AbstractMiddlewareClientApi {
  constructor(data){
    super('rest/change_mac_address_or_desc', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientChangeExtraMacApi extends AbstractMiddlewareClientApi {
  constructor(data){
    super('rest/change_extra_mac_address', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientChangePasswordApi extends AbstractMiddlewareClientApi {
  constructor(data){
    super('rest/client_auth_passwd_mail', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientInvoicePdfApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super(`rest/invoice_pdf/${data.id_invoice}`);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientMessagePdfApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super(`rest/client_message/${data.id_message}`, data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

class ClientConsentApi extends AbstractMiddlewareClientApi {
  constructor(data) {
    super('rest/client_consents', data);
    this.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access')}`;
  }
}

export {
  ClientLoginApi, ClientCheckTokenApi, ClientDataApi,
  ClientDebtApi, ClientFinancesApi, ClientContactDictionaryApi,
  ClientContactApi, ClientChangeDescOrMacApi, ClientChangeExtraMacApi,
  ClientChangePasswordApi, ClientChangePasswordSendApi,
  ClientChangePasswordTokenApi, ClientInvoicePdfApi,
  ClientMessagePdfApi, ClientConsentApi
}