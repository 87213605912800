import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ArrowDropDown } from '@styled-icons/material';
import { ArrowDropUp } from '@styled-icons/material';

import { LineContent } from '../content/line-content.component';
import { EditableService } from '../content/editable-service.component';

const Container = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 15px;
  margin-right: 15px;
`

const Row = styled.div`
  display: flex;
  width: 50%;
  margin: 10px 0;
  
  @media(max-width: 1000px){
    width: 100%;
  }
`

const Name = styled.span`
  display: block;
  font-size: 12px;
  flex: 1;

  @media(max-width: 1000px){
    font-size: var(--mobile-mini-size);
  }
`

const Value = styled.span`
  display: block;
  font-size: 12px;
  flex: 2;

  @media(max-width: 1000px){
    font-size: var(--mobile-mini-size);
  }
`

const LineWrapper = styled.div`
  display: flex;
`

const DroppedWrapper = styled.div`
  margin: 15px 0;
`

const sharedArrowStyle = css`
  width: 30px;
`

const StyledDropDownArrow = styled(ArrowDropDown)`
  ${sharedArrowStyle}
`

const StyledDropUpArrow = styled(ArrowDropUp)`
  ${sharedArrowStyle}
`


export const DropableContent = (props) => {
  const [dropped, setDropped] = useState(false);
  const { content } = props;

  // converting to array, as there is no option to map() or filter()
  const arrayContent = Array.from(content);

  // definig callback once
  // https://stackoverflow.com/a/46138370/12422260
  const onArrowClick = useCallback(e => {
    setDropped(!dropped)
  }, [dropped]);

  let arrow = '';

  // arrow - pointing up or down
  if (dropped) {
    arrow = <StyledDropUpArrow onClick={onArrowClick} />
  } else {
    arrow = <StyledDropDownArrow onClick={onArrowClick} />
  }

  let droppedContent = ''

  // displaying dropable content
  if (dropped) {
    droppedContent = arrayContent.map((item, index) => {

      // parameters but id and active_extras
      if (item[0] !== "id" && item[0] !== 'active_extras') {

        // editable parameters
        if (item[1].editable) {

          return <EditableService
            key={index}
            name={item[1].name}
            value={item[1].content}
            id={content.get('id').content}
            // type: mac or desc or extra
            type={item[0]}
            // to make services component refetch after change
            refetch={props.refetch}
            setRefetch={props.setRefetch}
          />
        }
        else {
          return <Row key={index}>
            <Name>{item[1].name}</Name>
            <Value>{item[1].content}</Value>
          </Row>
        }
      }
      // extra parameters (trash, sorry, could not do it better)
      else if (item[0] === 'active_extras') {
        let records = item[1].extras.map((extra, j) => {
          return <div key={`d${j}`}>
            {/* extra name */}
            <Row>
              <Name>{extra.name}</Name>
            </Row>

            {/* extras parameters */}
            {extra.content.map((element, i) => {
              if (element.editable) {
                return <EditableService
                  key={i}
                  name={element.name}
                  value={element.content}
                  id={extra.id}
                  // type: mac or desc or extra
                  type="extra"
                  // to make services component refetch after change
                  refetch={props.refetch}
                  setRefetch={props.setRefetch}
                />
              } else {
                return <Row key={`a${i}`}>
                  <Name>{element.name}</Name>
                  <Value>{element.content}</Value>
                </Row>
              }
            })}

          </div>
        })

        // 'Uslugi dodatkowe' and upper generated content
        return <div key={`c${index}`}>
          <LineContent content={item[1].name} />
          {records}
        </div>
      } else return null
    }
    );
  } else {
    droppedContent = "";
  }

  return (
    <Container>
      <LineWrapper>
        <LineContent content={content.get('tariff_name').content} />
        {arrow}
      </LineWrapper>
      <DroppedWrapper>
        {droppedContent}
      </DroppedWrapper>

    </Container>
  )
}