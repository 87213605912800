import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Input = styled.input`
  display: block;
  height: 20px;
  margin-left: 10px;
  padding: 3px 5px;

  @media(max-width: 1000px){
    width: 80px;
    font-size: var(--mini-mobile-size);
  }
`

const InputsWrapper = styled.div`
  display: flex;
`

const Balance = styled.span`
  display: block;
  font-size: var(--title-size);
  margin-right: 50px;

  @media(max-width: 1000px){
    font-size: var(--mini-size);
    margin-right: 10px;
  }
`

export const FilterTableRow = (props) => {

  return (
    <Container>
      <InputsWrapper>
        <Input placeholder="Filtruj po firmie" onChange={e => {
          props.setCompany(e.target.value);
        }} />
        <Input placeholder="Filtruj po roku wystawienia" onChange={e => {
          props.setYear(e.target.value);
        }}/>
      </InputsWrapper>
      <Balance>Saldo: <b>{props.balance}</b>zł</Balance>
    </Container>
  )
}