import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { ContentBox } from '../components/boxes/content-box.component';
import { TableRowWrapper, TableRowWithDetailsWrapper } from '../components/content/table-row-wrapper.component';
import { Details } from './start.page';
import { ClientDataApi } from '../api/api-client';

const Container = styled.div`
  margin-bottom: 100px;

  @media(max-width: 1000px){
    margin-top: 125px;
    font-size: var(--mini-size);
  }
`

const DetailsWrapper = styled.div`
  padding: 30px;
  text-align: center;
  cursor: pointer;
`

const DummyDiv = styled.div`
`

export const MessagesPage = () => {

  const [data, setData] = useState({});
  // refetch, when message changes status to 'odczytana'
  const [refetch, setRefetch] = useState(false);
  const [nOfMessages, setNOfMessages] = useState(4);
  // scrolling bottom vide: https://stackoverflow.com/a/45905418/12422260
  const bottomRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientDataApi().get();
      setData(result.data);
    }
    fetchData();
  }, [refetch]);

  useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [nOfMessages]);

  let messages = [];

  if (Object.keys(data).length !== 0) {

    const sorted_messages = data.clientmessage_set.sort((a, b) => {
      return b.id - a.id;
    })
    const last_n_messages = sorted_messages.slice(0, nOfMessages);

    const final_form_messages = last_n_messages.map((element) => {
      const status = element.read_time ? 'Odczytana' : 'Nowa';
      return [element.created.split('T')[0], element.short_desc, status, element.id, element.txt_message];
    })

    messages = final_form_messages.map((item, index) => {
      // if contains plain text
      if (item.slice(-1)[0] !== '') {
        return <TableRowWithDetailsWrapper
          key={index}
          content={item.slice(0, 3)}
          id={item[3]}
          foldableContent={item.slice(-1)}
          version="message"
          refetch={refetch}
          setRefetch={setRefetch} />
      } else {
        return <TableRowWithDetailsWrapper
          key={index}
          content={item.slice(0, 3)}
          id={item[3]}
          version="fileMessage"
          refetch={refetch}
          setRefetch={setRefetch} />
      }
    });
  }

  return (
    <Container>
      {Object.keys(data).length !== 0 &&
        <ContentBox title="Wiadomości" size="100%" margin="0">
          <TableRowWrapper content={['Data', 'Temat', 'Status']} background={'var(--lightgray)'} top={true} />
          {messages}
          <DetailsWrapper>
            <Details onClick={() => {
              setNOfMessages(nOfMessages + 6);
            }}>
              Zobacz więcej
          </Details>
          </DetailsWrapper>
        </ContentBox>
      }
      <DummyDiv ref={bottomRef}></DummyDiv>
    </Container>
  )
}
