import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';

import { ClientChangeDescOrMacApi, ClientChangeExtraMacApi } from '../../api/api-client';
import { FormFeedback } from './editable-content.component';

const Container = styled.form`
  width: 50%;
  display: flex;
  margin: 10px 0;

  @media(max-width: 1000px){
    width: 100%;
  }
`
const Title = styled.p`
  flex: 1;
  font-size: 12px;
  margin: 0;

  @media(max-width: 1000px){
    font-size: var(--mobile-mini-size);
  }
`

const InputContainer = styled.div`
  flex: 1;

  @media(max-width: 1000px){
    margin: 0 10px;
  }
`

const Input = styled.input`
  width: 11em;

  @media(max-width: 1000px){
    font-size: var(--mobile-mini-size);
  }
`

const Row = styled.div`
  display: flex;
  flex: 2;
`

const Value = styled.span`
  display: block;
  font-size: 12px;
  flex: 1;

  @media(max-width: 1000px){
    font-size: var(--mobile-mini-size);;
  }
`

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;

  @media(max-width: 1000px){
    margin-right: auto;
  }
`

const sharedArrowStyle = css`
  display: block;
  background-color: var(--background);
  font-size: 12px;
  text-decoration: underline;
  border: none;
  padding: 0;
  margin-right: 15px;
  align-self: flex-start;

  @media(max-width: 1000px){
    ${'' /* to alignt to right */}
    ${'' /* margin-left: auto; */}
  }
`

const Button = styled.span`
  ${sharedArrowStyle}
`

const Submit = styled.input`
  ${sharedArrowStyle}
`

export const EditableService = (props) => {
  const [formVisible, setFormVisible] = useState(false);
  const [serverFeedback, setServerFeedback] = useState('');

  const onEditClick = useCallback(e => {
    setFormVisible(!formVisible)
  }, [formVisible]);

  const formik = useFormik({
    initialValues: {
      field: props.value,
    },
    onSubmit: async (values) => {
      try {
        let payload = {};
        let result = '';

        // changing base service properties
        if (props.type.includes('mac') || props.type.includes('desc')) {

          if (props.type.includes('mac')) {
            payload.content_type = 'mac';
          } else if (props.type.includes('desc')) {
            payload.content_type = 'desc';
          }
          payload.comp_id = props.id;
          payload.content = values.field;

          result = await new ClientChangeDescOrMacApi(payload).put();
        // changing extra properties
        } else if (props.type.includes('extra')) {
          payload.extra_id = props.id;
          payload.mac = values.field;
          result = await new ClientChangeExtraMacApi(payload).put();
        }
        if (result.status === 200) {
          props.setRefetch(!props.refetch);
          setFormVisible(false);
        } else {
          let content = result.data.detail;
          if (!content){
            content = result.data[0];
          }
          setServerFeedback(content);
        }
      } catch (e) {
        
      }
    },
  });

  let content = '';

  if (formVisible) {
    content = <Row>
      <InputContainer>
        <Input id="field" {...formik.getFieldProps('field')}></Input>
        <FormFeedback>
          {formik.errors.field ? (
            <>{formik.errors.field}</>
          ) : null}
          {serverFeedback}
        </FormFeedback>
      </InputContainer>
      <ButtonContainer>
        <Button onClick={onEditClick}>Cofnij</Button>
        <Submit type="submit" value="Zmień" />
      </ButtonContainer>
    </Row>
  } else {
    content = <Row>
      <Value>{props.value}</Value>
      <ButtonContainer>
        <Button onClick={onEditClick}>Edytuj</Button>
      </ButtonContainer>
    </Row>

  }

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Title>{props.name}</Title>
      {content}
    </Container>
  )
}