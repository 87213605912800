import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ClientDataApi } from '../../api/api-client';
import { FormFeedback } from './editable-content.component';

const Container = styled.form`
  display: flex;
`

const Select = styled.select`
  height: 30px;
  width: 150px;

  @media(max-width: 1000px){
    width: 100px;
    font-size: var(--mini-size);
  }
`

const Input = styled.input`
  height: 25px;
  
  @media(max-width: 1000px){
    width: 120px;
    font-size: var(--mini-size);
  }
`

const Button = styled.button`
  height: 30px;
  font-size: var(--mini-size);
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2em;
`

export const AddContact = (props) => {
  const [feedback, setFeedback] = useState('');
  const data = props.clientContact;
  let contact_types = [];

  const formik = useFormik({
    initialValues: {
      type: '',
      contact: '',
    },
    validationSchema: Yup.object({
      type: props.stringValidation,
      contact: props.contactValidation
    }),
    onSubmit: async (values) => {
      try {
        const result = await new ClientDataApi({ 'clientcontact_set': [{ 'contact': values.contact, 'name': values.type }] }).patch();
        if (result.status === 200) {
          props.setRefetch(!props.refetch);
          setFeedback('');
        }
      } catch (e) {
        setFeedback('Nie udało się dodać nowego kontaktu');
      }
    },
  });

  // depending on contact type, append diffrent options
  // to 'type' comboBox
  if (Object.keys(data).length !== 0) {
    if (props.type === 'phone') {

      const allowed_phones = data.filter((element) => {
        return !element.mail;
      })

      contact_types = allowed_phones.map((element, index) => {
        return <option value={element.codename} key={index}>
          {element.name}
        </option>
      })

    } else if (props.type === 'email') {

      const allowed_mails = data.filter((element) => {
        return element.mail;
      })

      contact_types = allowed_mails.map((element, index) => {
        return <option value={element.codename} key={index}>
          {element.name}
        </option>
      })
    }
  }

  return (
    <Container onSubmit={formik.handleSubmit}>

      <Column>
        <Select
          name="type"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.type}
        >
          <option value="" label="Wybierz typ" />
          {contact_types}
        </Select>
        <FormFeedback>
          {formik.errors.type ? (
            <>{formik.errors.type}</>
          ) : " "}
          {feedback}
        </FormFeedback>
      </Column>

      <Column>
        <Input
          name="contact"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.contact}
        />
        <FormFeedback>
          {formik.errors.contact ? (
            <>{formik.errors.contact}</>
          ) : " "}
        </FormFeedback>
      </Column>

      <Button type="submit">Dodaj</Button>

    </Container>
  )
}