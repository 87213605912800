import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  background-color: var(--lightgray);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const GreyBox = (props) => {

  return (
    <Container>
      {props.children}
    </Container>
  )
}