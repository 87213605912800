import React from 'react';
import styled from 'styled-components';

import { ClientConsentApi } from '../../api/api-client';

const Container = styled.div`
  width: 100%;
  display: flex;

  @media(max-width: 1000px){
    font-size: var(--mini-size);
  }
`

const Label = styled.p`
  display: block;
  flex: 1;
  
  @media(max-width: 1000px){
    flex: 1;
  }
`

const CheckboxContainer = styled.div`
  flex: 2;
  text-align: left;
  align-self: center;
  
  @media(max-width: 1000px){
    flex: 1;
    text-align: center;
  }
`

const Checkbox = styled.input`
`


export const ConsentContent = (props) => {

  // API is broken, it compares strings
  return (
    <Container>
      <Label>{props.label}</Label>
      <CheckboxContainer>
        <Checkbox type="checkbox" defaultChecked={props.checked} onClick={async (e) => {
          let payload = {};
          const name = props.apiName;
          payload[name] = e.target.checked;
          await new ClientConsentApi(payload).patch();
        }} />
      </CheckboxContainer>
    </Container>
  )
}