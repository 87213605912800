import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ContentBox } from '../components/boxes/content-box.component';
import { GreyBox } from '../components/boxes/grey-box.component';
import { InputAndLabel } from '../components/content/input-and-label.component';
import { KorbankButton, BigButton } from '../components/content/buttons.component';
import { ClientLoginApi, ClientChangePasswordSendApi } from '../api/api-client';

const Container = styled.div`
  margin-top: 100px;
  width: 100%;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }

  @media(max-width: 1000px){
    margin-top: 50px;
  }
`

const OldPanelButton = styled.a`
  padding: 5px 20px;
  background-image: var(--korbank-grandient);
  color: white;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
`

const SiteTitle = styled.h3`
  text-align: center;
  font-size: var(--nav-size);
  display: block;  
`

const BoxContainer = styled.form`
  margin-top: -10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Feedback = styled.p`
  width: 100%;
  color: red;
  margin: 0;
  text-align: left;
  margin-top: 5px;
`

const InputWrapper = styled.div`
  margin-top: 20px;
`

const ButtonWrapper = styled.div`
  margin: 15px 0;
`

const ResetBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
`

const FeedbackWrapper = styled.div`
  margin-top: 10px;
`

const ResetFeedback = styled.p`
  margin: 5px 10px;
`

export const LoginPage = (props) => {
  const [showReset, setShowReset] = useState(false);
  const [resetFeedback, setResetFeedback] = useState('');
  const [feedback, setFeedback] = useState('')
  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Pole jest wymagane'),
      password: Yup.string()
        .required('Pole jest wymagane'),
    }),

    onSubmit: async (values) => {
      try {

        const result = await new ClientLoginApi({
          username: values.username,
          password: values.password
        }).post()

        if (result.status === 200) {
          sessionStorage.setItem('access', result.data.access);
          sessionStorage.setItem('login', values.username);
          history.push("/start");
        }
      }
      catch (e) {
        // https://axios-http.com/docs/handling_errors
        if (e.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setFeedback('Niepoprawny login lub hasło');
        } else if (e.request) {
          // The request was made but no response was received
          setFeedback('Błąd połączenia z serwerem');
        } else {
          // Something happened in setting up the request that triggered an Error
          setFeedback('Wystąpił nieznany błąd. Skontaktuj się z Biurem Obsługi Klienta');
        }
      }
    }
  });

  const resetFormik = useFormik({
    initialValues: {
      username: '',
      email: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Pole jest wymagane'),
      email: Yup.string()
        .email('Email jest niepoprawny')
        .required('Pole jest wymagane'),
    }),

    onSubmit: async (values) => {
      const result = await new ClientChangePasswordSendApi(values).post()
      if (result.status === 200) {
        setResetFeedback('Email z linkiem aktywującym został wysłany. ');
      } else {
        setResetFeedback('Podany użytkownik nie istnieje, lub użytkownik nie podał takiego adresu email. ');
      }
    }
  });


  return (
    <Container>
      <OldPanelButton href="https://mid.panelklienta.k.pl/">
        Stary Panel Klienta
      </OldPanelButton>
      <SiteTitle>
        Witamy w Panelu Klienta firmy Korbank
      </SiteTitle>

      <ContentBox title="Logowanie" size='35%' margin="0px">

        {/* LOG IN */}

        <BoxContainer onSubmit={formik.handleSubmit}>

          <GreyBox>
            <InputWrapper>
              <InputAndLabel label="Login" placeholder="Login / identyfikator klienta" id="username" {...formik.getFieldProps('username')} />
              {formik.touched.username && formik.errors.username ? (
                <Feedback>{formik.errors.username}</Feedback>
              ) : null}
            </InputWrapper>

            <InputWrapper>
              <InputAndLabel label="Hasło" type="password" id="password" {...formik.getFieldProps('password')} />
              {formik.touched.password && formik.errors.password ? (
                <Feedback>{formik.errors.password}</Feedback>
              ) : null}
              <FeedbackWrapper>
                {feedback}
              </FeedbackWrapper>

            </InputWrapper>

            <ButtonWrapper>
              <KorbankButton content="Zaloguj" />
            </ButtonWrapper>
          </GreyBox>
        </BoxContainer>

        {/* RESET PASSWORD */}

        <ResetBox>
          <BigButton content="Przypomnij hasło" onClick={() => {
            setShowReset(!showReset);
          }} />
          <FeedbackWrapper>
            <ResetFeedback>
              {resetFeedback}
            </ResetFeedback>
          </FeedbackWrapper>
          {showReset &&
            <BoxContainer onSubmit={resetFormik.handleSubmit}>
              <InputWrapper>
                <InputAndLabel label="Login" id="username" {...resetFormik.getFieldProps('username')} />
                {resetFormik.touched.username && resetFormik.errors.username ? (
                  <Feedback>{resetFormik.errors.username}</Feedback>
                ) : null}
              </InputWrapper>

              <InputWrapper>
                <InputAndLabel label="Email" id="email" {...resetFormik.getFieldProps('email')} />
                {resetFormik.touched.email && resetFormik.errors.email ? (
                  <Feedback>{resetFormik.errors.email}</Feedback>
                ) : null}
              </InputWrapper>

              <ButtonWrapper>
                <KorbankButton content="Wyślij link aktywacyjny" />
              </ButtonWrapper>
            </BoxContainer>
          }
        </ResetBox>

      </ContentBox>

    </Container>
  )
}

export {
  Container, SiteTitle, BoxContainer, Feedback, ButtonWrapper,
  InputWrapper, FeedbackWrapper
}
