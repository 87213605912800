import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
`

const Label = styled.label`
  display: block;
  font-size: var(--mini-size);
`

const Input = styled.input`
  display: block;
  height: 25px;

  @media(max-width: 1000px){
    width: 120px;
    font-size: var(--mini-size);
  }
`


export const SmallInput = (props) => {

  return (
    <Container>
      <Label>{props.label}</Label>
      <Input type={props.type} placeholder={props.placeholder} {...props}></Input>
    </Container>
  )
}