import React from 'react';
import styled from 'styled-components';

const Line = styled.p`
  font-size: var(--content-size);
`

export const LineContent = (props) => (
  <Line>
    {props.content}
  </Line>
)