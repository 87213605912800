import React from 'react';
import styled, {css} from 'styled-components';

// https://stackoverflow.com/questions/49618997/idiomatic-way-to-share-styles-in-styled-components
const base = css`
  padding: 5px 50px;
  display: block;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`

const KorInput = styled.input`
  ${base}
  background-image: var(--korbank-grandient);
`

const YellowInput = styled.button`
  ${base}
  background-color: #ffde16;
  color: black;
`

export const KorbankButton = (props) => (
  <KorInput type="submit" value={props.content} />
)


// https://github.com/formium/formik/issues/1610#issuecomment-529572094
export const BigButton = (props) => (
  <YellowInput type="button" onClick={props.onClick}>{props.content}</YellowInput>
)
