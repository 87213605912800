import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { SmallInput } from './small-input.component';
import { FormFeedback } from './editable-content.component';
import { ClientChangePasswordApi } from '../../api/api-client';

const Container = styled.form`
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  margin-bottom: 10px;
`

const Submit = styled.input`
  display: block;
  width: 100px;
  background-color: white;
  padding: 5px 0;
  border-radius: 5px;
  margin-bottom: 20px;
  
`

export const ChangePassword = (props) => {

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatedNewPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: props.validation,
      newPassword: Yup.string()
        .min(12, 'Hasło musi mieć co najmniej 12 znaków'),
      repeatedNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Nowe hasła nie są identyczne')
    }),

    onSubmit: async (values) => {
      try {
        const result = await new ClientChangePasswordApi({
          username: sessionStorage.getItem('login'),
          password: values.oldPassword,
          new_password: values.newPassword
        }).post();

        if (result.status === 200) {
          props.setShow('');
          props.setFeedback('Hasło zostało zmienione');
        } else {
          // two types of answer from kerp: json and array
          let content = result.data.detail;
          if (!content){
            content = result.data[0];
          }
          props.setFeedback(content);
        }
      } catch (e) {
        props.setFeedback('Nieznany błąd');
      }
    }
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <SmallInput
          placeholder="Stare hasło"
          id="oldPassword"
          type="password"
          {...formik.getFieldProps('oldPassword')}
        />
        {formik.touched.oldPassword && formik.errors.oldPassword ? (
          <FormFeedback>{formik.errors.oldPassword}</FormFeedback>
        ) : null}
      </InputWrapper>
      <InputWrapper>
        <SmallInput
          placeholder="Nowe hasło"
          id="newPassword"
          type="password"
          {...formik.getFieldProps('newPassword')} />
        {formik.touched.newPassword && formik.errors.newPassword ? (
          <FormFeedback>{formik.errors.newPassword}</FormFeedback>
        ) : null}
      </InputWrapper>
      <InputWrapper>
        <SmallInput
          placeholder="Powtórz nowe hasło"
          id="repeatedNewPassword"
          type="password"
          {...formik.getFieldProps('repeatedNewPassword')} />
        {formik.touched.repeatedNewPassword && formik.errors.repeatedNewPassword ? (
          <FormFeedback>{formik.errors.repeatedNewPassword}</FormFeedback>
        ) : null}
      </InputWrapper>
      <Submit type="submit" value="zmień" />
    </Container>
  )
}