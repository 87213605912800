import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ContentBox } from '../components/boxes/content-box.component';
import { KeyValueContent } from '../components/content/key-value-content.component';
import { ClientDataApi, ClientDebtApi, ClientFinancesApi } from '../api/api-client';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  flex-wrap: wrap;

  /* https://stackoverflow.com/questions/4459821/css-selector-what-is-it */
  > div {
    margin-bottom: 25px;
  }

  @media(max-width: 1000px){
    margin-top: 100px;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
  }
`

const MidTitle = styled.h3`
  font-weight: bold;
`

const DetailsWrapper = styled.div`
  padding: 10px;
  text-align:center;
`

const Details = styled.a`
  font-size: var(--title-size);
  font-weight: bold;
  text-decoration: none;
  color: inherit;
`

const StyledLink = styled(Link)`
  font-size: var(--title-size);
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  margin-bottom: auto;
`

const StartPage = (props) => {
  const [data, setData] = useState({});
  const [debt, setDebt] = useState({});
  const [finances, setFinances] = useState({});
  let lines = [], kv_values = [], invoices = [], client_data = [], messages = [], last_payment = '';

  // https://www.robinwieruch.de/react-hooks-fetch-data/
  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientDebtApi().get();
      setDebt(result.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientDataApi().get();
      setData(result.data);
    }
    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await new ClientFinancesApi().get();
      setFinances(result.data);
    }
    fetchData();
  }, [])

  // if data state is not empty
  if (Object.keys(data).length !== 0) {
    const active_computers = data.active_computers.map((item) => (
      [item.devicename, item.tariff_name]
    ))

    lines = active_computers.map((item, index) => (
      <KeyValueContent content={item} key={index} />
    ));

    client_data = [['Numer Klienta', data.code], ['Imię i nazwisko/nazwa firmy', `${data.name} ${data.surname}`],
    ['Adres zameldowania/siedziby', `${data.main_data.address_1st_line} ${data.main_data.address_2nd_line}`],
    ['Adres na fakturze', `${data.data_for_inv.address_1st_line} ${data.data_for_inv.address_2nd_line}`]].map((item, index) => (
      <KeyValueContent content={item} key={index} />
    ));

    const last_three_messages = data.clientmessage_set.sort((a, b) => {
      return b.id - a.id;
    }).slice(0, 3);

    const messages_final_form = last_three_messages.map((element) => {
      return [element.created.split('T')[0], element.short_desc];
    });

    messages = messages_final_form.map((item, index) => (
      <KeyValueContent content={item} key={index} />
    ));
  }

  // if debt state is not empty
  if (Object.keys(debt).length !== 0) {
    kv_values = [["Saldo konta", `${debt.balance}zł`], ["Zadłużenie", `${debt.debt_value}zł`]].map((item, index) => (
      <KeyValueContent content={item} key={index} />
    ));
  }

  if (Object.keys(finances).length !== 0) {
    const sorted_dates = finances.financial_documents.sort((a, b) => {
      return Date.parse(b.date) - Date.parse(a.date);
    });

    if (sorted_dates.length > 1) {
      invoices = [[Number.parseFloat(sorted_dates[0].to_pay) === 0, sorted_dates[0].nr, `${sorted_dates[0].brutto}PLN`, sorted_dates[0].date.split('T')[0]],
      [Number.parseFloat(sorted_dates[1].to_pay) === 0, sorted_dates[1].nr, `${sorted_dates[1].brutto}PLN`, sorted_dates[1].date.split('T')[0]]].map((item, index) => (
        <KeyValueContent content={item} key={index} />
      ))
    }

    last_payment = sorted_dates.find(element => {
      if (element.codename === "WPL") {
        return element
      } return '';
    })

    // cannot do it in .find(). It can just return array content
    if (last_payment)
      last_payment = <KeyValueContent content={['Ostatnia wpłata', `${last_payment.brutto}zł`, last_payment.maturity]} />;

  }


  return (
    <Container>
      {Object.keys(data).length !== 0 &&
        Object.keys(debt).length !== 0 &&
        Object.keys(finances).length !== 0 &&
        <>
          <ContentBox title="Moje Usługi">
            {lines}
            <DetailsWrapper>
              <StyledLink to="/services">
                Zobacz &gt;
              </StyledLink>
            </DetailsWrapper>
          </ContentBox>

          <ContentBox title="Rachunki i płatności">
            {kv_values}
            {last_payment}
            <MidTitle>
              Ostatnie faktury
            </MidTitle>

            {invoices}
            <DetailsWrapper>
              <StyledLink to="/payments">
                Zobacz wszystkie &gt;
              </StyledLink>
            </DetailsWrapper>
          </ContentBox>

          <ContentBox title="Sprawdź oferty specjalnie dla Ciebie!">
            <DetailsWrapper>
              <Details href="https://korbank.pl" target='_blank'>
                Zobacz &gt;
              </Details>
            </DetailsWrapper>
          </ContentBox>

          <ContentBox title="Ostatnie wiadomości" size="47%">
            {messages}

            <DetailsWrapper>
              <StyledLink to="/messages">
                Zobacz wszystkie &gt;
              </StyledLink>
            </DetailsWrapper>
          </ContentBox>

          <ContentBox title="Moje Dane" size="47%">
            {client_data}

            <DetailsWrapper>
              <StyledLink to="/account">
                Aktualizuj &gt;
              </StyledLink>
            </DetailsWrapper>
          </ContentBox>
        </>
      }
    </Container>
  )
}

export { StartPage, Details, Container }
