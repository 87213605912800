import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ClientContactApi } from '../../api/api-client';

const Container = styled.form`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 95%;

  @media(max-width: 1000px){
    font-size: var(--mini-size);
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
`

const Content = styled.span`
  display: block;
`

const Edit = styled.button`
  display: block;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  background-color: var(--background);
  
  @media(max-width: 1000px){
    font-size: var(--mini-size);
  }
`

const Input = styled.input`
  padding: 3px 10px;

  @media(max-width: 1000px){
    width: 100px;
    font-size: var(--mini-size);
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: -10px;
`

const FormFeedback = styled.p`
  color: red;
  font-size: var(--mini-size);
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const EditableContent = (props) => {

  let content = '';
  let buttons = ''

  const [formVisible, setFormVisible] = useState(false);
  const [serverFeedback, setServerFeedback] = useState('');

  const onEditClick = useCallback(e => {
    setFormVisible(!formVisible)
  }, [formVisible])

  const formik = useFormik({
    initialValues: {
      field: props.content[1],
    },
    validationSchema: Yup.object({
      field: props.validation
    }),
    onSubmit: async (values) => {
      try {
        const result = await new ClientContactApi({ contact: values.field, id: props.id }).patch();
        if (result.status === 200) {
          // fetch again main page
          props.setRefetch(!props.refetch);
          setFormVisible(false);
        }
      } catch (e) {
        setServerFeedback('Błąd zmiany kontaktu');
      }

    },
  });

  if (formVisible) {
    content = <Column>
      <Input id="field" {...formik.getFieldProps('field')} />
      <FormFeedback>
        {formik.errors.field ? (
          <>{formik.errors.field}</>
        ) : null}
        {serverFeedback}
      </FormFeedback>
    </Column>

    buttons = <ButtonsWrapper>
      {/* submits edit, here formik submission works */}
      <Edit type="submit">Zmień</Edit>

      {/* just deletes record with given id */}
      {/* https://github.com/formium/formik/issues/1610 */}
      <Edit type="button" onClick={async (e) => {
        try {

          const result = await new ClientContactApi({ id: props.id }).delete();
          if (result.status === 200) {
            // fetch again main page
            props.setRefetch(!props.refetch);
          }
        } catch (e) {
          setServerFeedback('Nie można kontaktu znajdującego się na umowie');
        }

      }}>Usuń</Edit>
      <Edit onClick={onEditClick}>Cofnij</Edit>
    </ButtonsWrapper>

  } else {
    content = <Content>{props.content[1]}</Content>
    buttons = <Edit onClick={onEditClick}>Edytuj</Edit>
  }

  return (
    <Container onSubmit={formik.handleSubmit}>
      <ContentContainer>
        <Content>{props.content[0]}</Content>
        {content}
      </ContentContainer>
      {buttons}
    </Container>
  )
}

export { FormFeedback }