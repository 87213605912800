// https://medium.com/@SilentHackz/simple-way-to-secure-react-apps-using-jwt-and-react-router-2b4a05d780a3
export const getSession = () => {
  const jwt = sessionStorage.getItem('access');
  let session
  try {
    if (jwt) {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      session = JSON.parse(window.atob(base64))

      // https://stackoverflow.com/a/221297/12422260
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (currentTimestamp > session.exp) {
        return false
      } else return true;
    }
  } catch (error) {
    // just skip it
  } return false;
}

export const logOut = (history) => {
  sessionStorage.removeItem('access');
  history.push('/');
}