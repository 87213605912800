import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`
const Value = styled.div`
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/flex */
  flex: 1;
`

export const TableRowContent = (props) => {
  const line = props.content.map((item, index) => (
    <Value key={index}>{item}</Value>
  ));

  return (
    <Container onClick={props.onClick}>
      {line}
    </Container>
  )
}