import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Link,
  NavLink,
  withRouter,
} from "react-router-dom";

import { StartPage } from './pages/start.page.jsx';
import { ServicesPage } from './pages/services.page';
import { FinancesPage } from './pages/finances.page';
import { LoginPage } from './pages/login.page';
import { MessagesPage } from './pages/messages.page';
import { AccountPage } from './pages/account.page';
import { ResetPasswordPage } from './pages/reset-password.page';
import { Menu, Burger } from './components/content/hamburger.component';
import { PrivateRoute } from './components/routes/private-route.component';
import { GuestOnlyRoute } from './components/routes/guest-only-route.component'
import { logOut } from './components/routes/auth-utils';

const App = styled.div`
  width: 90%;
  margin:0 auto;
`

const NavContainer = styled.div`
  margin: 20px 0;
  display: flex;

  @media(max-width: 1000px){
    display: none;
  }
`

const Nav = styled.nav`
  width: 100%;

  ul{
    font-size: var(--nav-size);
    display:flex;
    justify-content: flex-start;

    li {
      list-style-type: none;
      display: inline-block;
      font-weight: bold;

      a {
        text-decoration:none;
        color:inherit;
        margin-left: 20px;
      }
    }
  }

  li:last-child {
    margin-left: auto;
  }
`

const Logo = styled.img.attrs({
  src: "/images/logo.svg"
})`
  width: 200px;
`

const Logout = styled.p`
  cursor: pointer;
  margin: 0;
`

const activeClassName = 'nav-item-active'

const StyledLink = styled(NavLink).attrs({ activeClassName })`
  &.${activeClassName} {
    color: var(--korbank-purple);
  }
`

const BurgerWrapper = styled.div`
  @media(min-width: 1000px){
    display: none;
  }
`

const LogoLink = styled(Link)`
  margin-top: 10px;
`

const Main = withRouter(({ location, history }) => {

  const [open, setOpen] = useState(false);
  const node = useRef();

  // https://medium.com/@sandip21/how-to-hide-navbar-footer-on-authentication-pages-in-reactjs-40714ee1ce48
  return (
    <div>

      {/* hide menu while in guest views */}
      {
        (location.pathname === '/start'
          || location.pathname === '/services'
          || location.pathname === '/payments'
          || location.pathname === '/messages'
          || location.pathname === '/account')
        &&
        <BurgerWrapper ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </BurgerWrapper>
      }


      {
        (location.pathname === '/start'
          || location.pathname === '/services'
          || location.pathname === '/payments'
          || location.pathname === '/messages'
          || location.pathname === '/account')
        &&
        <NavContainer>
          <LogoLink to="/start">
            <Logo />
          </LogoLink>
          <Nav>
            <ul>
              <li>
                <StyledLink exact to="/start">START</StyledLink>
              </li>
              <li>
                <StyledLink to="/services">USŁUGI</StyledLink>
              </li>
              <li>
                <StyledLink to="/payments">PŁATNOŚCI</StyledLink>
              </li>
              <li>
                <StyledLink to="/messages">WIADOMOŚCI</StyledLink>
              </li>
              <li>
                <StyledLink to="/account">MOJE KONTO</StyledLink>
              </li>
              <li>
                <Logout onClick={() => logOut(history)}>WYLOGUJ</Logout>
              </li>
            </ul>

          </Nav>
        </NavContainer>

      }

      <Switch>
        <PrivateRoute component={AccountPage} path="/account" />
        <PrivateRoute component={StartPage} path="/start" />
        <PrivateRoute component={FinancesPage} path="/payments" />
        <PrivateRoute component={MessagesPage} path="/messages" />
        <PrivateRoute component={ServicesPage} path="/services" />
        <GuestOnlyRoute component={ResetPasswordPage} path='/passwd_reset' />
        <GuestOnlyRoute component={LoginPage} path="/" />
      </Switch>
    </div>
  )
})

export const Application = () => {
  return (
    <App>
      <Router>
        <Main />
      </Router>
    </App>
  );
}

export { StyledLink }