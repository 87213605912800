import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid var(--korbank-purple);
  border-radius: 5px;
  width: ${(props) => (props.size ? props.size : '30%')};
  animation: fade 0.5s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }

  @media(max-width: 1000px){
    width: 100%;
    margin: 30px auto;
  }
`

const TextWrapper = styled.div`
  margin-left: ${(props) => (props.margin ? props.margin : '10px')};
`

const Title = styled.h3`
  margin: 0 0 10px 0 !important;
  display: block;
  padding: 7px 0;
  text-align: center;
  background-image: var(--korbank-grandient);
  color: white;
  font-size: var(--title-size);
`

export const ContentBox = (props) => {

  return (
    <Container size={props.size}>
      <Title>{props.title}</Title>
      <TextWrapper margin={props.margin}>
        {props.children}
      </TextWrapper>
    </Container>
  )

}

export { TextWrapper }